import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { ASSET_IMAGE_URL } from "../config/constant";

// import instaLogo from "../assets/images/icon_instagram.png";
// import fbLogo from "../assets/images/icon_facebook.png";
// import gmapLogo from "../assets/images/icon_Google_map.png";
// import twLogo from "../assets/images/icon_twitter.png";
// import ytLogo from "../assets/images/icon_youtube.png";

const instaLogo = `${ASSET_IMAGE_URL}/icon_instagram.png`;
const fbLogo = `${ASSET_IMAGE_URL}/icon_facebook.png`;
const gmapLogo = `${ASSET_IMAGE_URL}/icon_Google_map.png`;
const twLogo = `${ASSET_IMAGE_URL}/icon_twitter.png`;
const ytLogo = `${ASSET_IMAGE_URL}/icon_youtube.png`;
const LinkedinLogo = `${ASSET_IMAGE_URL}/icon_Linkedin_big.png`;

class Footer extends Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            language: localStorage.getItem('language'),
        }
    }
 
    render() {
        return (
            <>
                <footer id="footer" className="custom-footer pb-4 pb-md-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="text-center text-lg-left d-md-flex justify-content-center justify-content-lg-start align-items-center pb-lg-3">
                                    <p className="mb-0">
                                        All Rights Reserved {new Date().getFullYear()}.
                                    </p>
                                    <Link
                                        to="/contact-us"
                                        className="footer-link"
                                    >
                                        Contact Us
                                    </Link>
                                    <Link
                                        to="/privacy"
                                        className="footer-link"
                                    >
                                        Privacy Policy
                                    </Link>
                                    <Link
                                        to="/terms"
                                        className="footer-link"
                                    >
                                        Terms
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-social mt-2 mt-lg-0">
                                    <div className="text-center text-lg-right">
                                        <a
                                            href="https://www.facebook.com/localspecialistnetwork/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="footer-social-icons"
                                        >
                                            <img src={fbLogo} alt="facebook Logo"/>
                                        </a>
                                        <a
                                            href="https://www.instagram.com/localspecialistnetwork/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="footer-social-icons"
                                        >
                                            <img src={instaLogo} alt="instagram Logo"/>
                                        </a>
                                        <a
                                            href="https://twitter.com/localprovider"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="footer-social-icons"
                                        >
                                            <img src={twLogo} alt="twitter Logo"/>
                                        </a>
                                        <a
                                            href="https://www.youtube.com/channel/UC1dGFDXY0SE7Pwsl8F_wjVw"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="footer-social-icons"
                                        >
                                            <img src={ytLogo} alt="you tube Logo"/>
                                        </a>
                                        <a
                                            href="https://www.google.com/maps/place/Local+Specialist+Network/@33.4352157,-127.2167536,10z/data=!4m5!3m4!1s0x0:0x742c560e73067d1f!8m2!3d37.2751794!4d-119.2731966"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="footer-social-icons"
                                        >
                                            <img src={gmapLogo} alt="google map Logo"/>
                                        </a>
                                        <a
                                            href="https://www.linkedin.com/company/local-specialist-network/" // https://www.linkedin.com/company/local-injury-network
                                            target="_blank"
                                            rel="noreferrer"
                                            className="footer-social-icons"
                                        >
                                            <img src={LinkedinLogo} alt="Linked in Logo"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.auth.currentUser,
    }
}

export default connect(mapStateToProps)(withRouter(Footer));

