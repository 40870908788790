import { SEARCH_RESULT } from "./action-types";

const initialState = { };

export const searchResult = (
  state = initialState,
  { type, payload = null }: any
): any => {
  switch (type) {
    case SEARCH_RESULT:
      return {
        ...state,
        searchResult: payload,
      };

    default:
      return state;
  }
};
