import { createStore, applyMiddleware, compose } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
// import { createLogger } from 'redux-logger';
import rootReducer, { RootState } from './reducers';
import { AUTH_INITIAL_TYPES } from './auth/action-types';

let middleware = [thunk as ThunkMiddleware<RootState,AUTH_INITIAL_TYPES>]

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

let composeEnhancers = compose;

if (process.env.NODE_ENV !== 'production') {
    // const loggerMiddleware = createLogger();
    middleware = [...middleware] // , loggerMiddleware
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

export const store = createStore(
    rootReducer,
    {},
    composeEnhancers(applyMiddleware(...middleware))
);
