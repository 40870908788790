import { MiddleWare, PayLoad } from '../../interfaces/auth';

// auth action types
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_INITIAL = 'AUTH_INITIAL';
export const GET_USER = 'GET_USER';
export const USER_NAME = 'USER_NAME';
export const SET_RTL = 'SET_RTL';
export const USER_TYPE = 'USER_TYPE';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const CURRENTUSER_CHANGE = 'CURRENTUSER_CHANGE';
export const IS_LOCAL_STORAGE_SET_SUCCESS = 'IS_LOCAL_STORAGE_SET_SUCCESS';
export const SIGNUP_DATA = 'SIGNUP_DATA';
interface AUTH_INITIAL_ACTION {
  type: typeof AUTH_INITIAL;
  middleware: MiddleWare;
}

interface AUTH_LOGIN_ACTION {
  type: typeof AUTH_LOGIN;
  payload: PayLoad;
}

interface SET_RTL_ACTION {
  type: typeof SET_RTL;
  payload: PayLoad;
}

interface USER_TYPE_ACTION {
  type: typeof USER_TYPE;
  payload: PayLoad;
}

interface AUTH_LOGIN_SUCCESS_ACTION {
  type: typeof AUTH_LOGIN_SUCCESS;
  payload: PayLoad;
}

interface CURRENTUSER_CHANGE_ACTION {
  type: typeof CURRENTUSER_CHANGE;
  payload: PayLoad;
}

interface AUTH_LOGOUT_ACTION {
  type: typeof AUTH_LOGOUT;
  payload: PayLoad;
}

interface IS_LOCAL_STORAGE_SET_SUCCESS_ACTION {
  type: typeof IS_LOCAL_STORAGE_SET_SUCCESS;
  payload: PayLoad;
}

interface SIGHUP_DATA {
  type: typeof SIGNUP_DATA;
  payload: PayLoad;
}

export type AUTH_INITIAL_TYPES =
  | AUTH_INITIAL_ACTION
  | AUTH_LOGIN_ACTION
  | SET_RTL_ACTION
  | USER_TYPE_ACTION
  | AUTH_LOGIN_SUCCESS_ACTION
  | CURRENTUSER_CHANGE_ACTION
  | AUTH_LOGOUT_ACTION
  | IS_LOCAL_STORAGE_SET_SUCCESS_ACTION
  | SIGHUP_DATA