import React, { Component } from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import { logout } from "../lib/apis/auth";
import { connect } from "react-redux";
// import * as languageActions from "../lib/store/intl/actions";
// import logo from "../assets/images/Logo.png";
// import profileImage from "../assets/images/signupProfile.png";
import Modal from "react-bootstrap/Modal";
import {userBaseProfileURL, BLOG_URL} from "../config/constant"; // ASSET_IMAGE_URL,
import $ from 'jquery';

// const logo = `${ASSET_IMAGE_URL}/Logo.png`;
const logo = `/New_Logo_v1.0.png`;

class Header extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      language: localStorage.getItem("language") !== "" && localStorage.getItem("language") !== "" ? localStorage.getItem("language") : "en",
      practitionerData: localStorage.getItem("practitionerData") !== "" ? localStorage.getItem("practitionerData") : "",
      showLogoutModal: false,
      showNavbar: false,
    };
  }

  toggleLogoutModal = () => {
    if (this.state.showLogoutModal === false) {
      this.setState({
        showLogoutModal: true,
      });
    } else {
      this.setState({
        showLogoutModal: false,
      });
    }
  };

  // handleLanguageChange = (lang: any) => {
  //   this.props.dispatch(languageActions.updateLanguage(lang));
  //   localStorage.setItem("language", lang);
  //   this.setState({ language: lang });
  //   this.props.dispatch(language(lang));
  // };

  componentDidMount() {
    document.onkeydown = (evt) => {
      if (evt.keyCode === 27) {
        this.setState({
          showLogoutModal: false,
        })
      }
    }
    window.addEventListener('resize', () => {
      this.setState({
        showNavbar: false,
      });
    });
    setTimeout(() => {
      // 	setMinHeight();
      // 	var navHeight = $('.custom-header').height();
      // 	$('.home.banner-section').css('padding-top', navHeight + 'px');
      // 	// $('.custom-footer').removeClass('d-none')

      document.getElementById("loading")?.classList.add("d-none");

      // 	$('.main_page').css('padding-top', navHeight + 'px');
    }, 500);

  }

  /**handle logout */
  handleLogout = () => {
    
      localStorage.removeItem("isLogin")
      localStorage.removeItem("access_token");
      localStorage.removeItem("practitionerData");
      
      this.props.dispatch(logout()).then((res:any)=>{
        // console.log(res)
      }).catch(()=> {

      })
      this.props.history.push("/")
  };
  shouldComponentUpdate(newProps: any, newState: any) {
    return true;
  }

  toggleNavbar = () => {
    // console.log("object called");
    if (this.state.showNavbar === false) {
      this.setState({
        showNavbar: true,
      });
    } else {
      this.setState({
        showNavbar: false,
      });
    }
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    
    /** Scroll top on next page */
    // window.scroll(0,0)
      let minHeight = window.innerHeight - $(".custom-header").height();

      $('.main_page').css("min-height" , `${minHeight}px`)
    

  }

  render() {
    return (
      <>
        <header id="header" className="custom-header">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-sticky px-0 border-0">
              <div className="navbar-brand">
                <Link to="/" className="d-inline-block" onClick={() => {
                  this.setState({
                    showNavbar: false,
                  });
                  setTimeout(() => {
                    window.scroll(0,0)
                    document.getElementsByTagName("body")[0].scrollTo({top: 0, behavior: 'smooth'});
                  }, 200);
                  <Redirect to="/home" />
                }}>
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <div className="d-lg-none">
                <button
                  type="button"
                  id="hamburger-1"
                  onClick={this.toggleNavbar}
                  className={
                    (this.state.showNavbar === true
                      ? " open "
                      : " collapsed ") + " navbar-toggle hamburger"
                  }
                  data-target="#navbar-navigation"
                >
                  <span className="line"></span>
                  <span className="line"></span>
                  <span className="line"></span>
                </button>
              </div>
              <div
                // className="navbar-collapse collapse"
                className={
                  (this.state.showNavbar === true ? "show " : "hide ") +
                  "navbar-collapse collapse"
                }
                id="navbar-navigation"
              >
                <ul
                  className={
                    (this.props.isAuthenticated === true && this.props.isAuthenticated !== undefined ? "" : "login-nav") +
                    " p-0 flex-column flex-lg-row nav navbar-nav align-items-center ml-auto mt-2 mt-lg-0 align-items-lg-baseline align-items-lg-center"
                  }
                >
                  {/* " p-0 nav navbar-nav align-items-baseline ml-auto mt-2 mt-lg-0 align-items-md-baseline align-items-lg-center" */}
                  {/* <ul className="p-0 nav navbar-nav align-items-baseline ml-auto mt-2 mt-lg-0 align-items-md-baseline align-items-lg-center"> */}
                  {this.props.isAuthenticated === true && this.props.isAuthenticated !== undefined ? (
                  <>
                    <li className="nav-item float-right mr-lg-4 pt-4 pt-lg-0">
                      <Link
                        to="/dashboard"
                        onClick={this.toggleNavbar}
                        className="navbar-nav-link text-primary"
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li className="nav-item float-right dropdown mr-lg-4 pt-4 pt-lg-0">
                      <a
                        href={" "}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        className="navbar-nav-link dropdown-toggle"
                        id="navbarDropdown"
                        data-toggle="dropdown"
                        // aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Welcome,{' '}
                        {this.props.currentUser !== undefined ? this.props.currentUser.name : "John Doe" }
                        {this.props.currentUser !== undefined ? 
                          <img
                            // src={`${userBaseProfileURL}${this.props.currentUser.avatar}`}
                            src={
                              this.props.currentUser.avatar !== ""
                                ? `${userBaseProfileURL}${this.props.currentUser.avatar}`
                                : `${userBaseProfileURL}default.png`
                            }
                            alt="Profile pic"
                            className="profile-img"
                            onError = { (e:any) => {
                              e.target.onerror = null
                              e.target.src = `${userBaseProfileURL}${"default.png"}`
                            }}
                          />
                        : 
                          <img
                            src={`${userBaseProfileURL}default.png`}
                            alt="Profile pic"
                            className="profile-img"
                            onError = {(e:any) => {
                              e.target.onerror = null;
                              e.target.src = `${userBaseProfileURL}${"default.png"}`
                            }}
                          />
                        }
                      </a>
                      <ul
                        aria-labelledby="navbarDropdown"
                        className="dropdown-menu"
                      >
                        <li>
                          <Link
                            to="/profile"
                            onClick={() => this.toggleNavbar()}
                            className="nav-dropdown-link"
                          >
                            My Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/edit-profile"
                            onClick={() => this.toggleNavbar()}
                            className="nav-dropdown-link"
                          >
                            Edit Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/change-password"
                            onClick={() => this.toggleNavbar()}
                            className="nav-dropdown-link"
                          >
                            Change Password
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/payment-details"
                            onClick={() => this.toggleNavbar()}
                            className="nav-dropdown-link"
                          >
                            Payment
                          </Link>
                        </li>
                        <li>
                          <a
                            href={" "}
                            className="nav-dropdown-link"
                            onClick={(e) => {
                              this.toggleLogoutModal();
                              e.preventDefault();
                            }}
                          >
                            Logout
                          </a>
                        </li>
                      </ul>
                    </li>
                  </>
                  ) : (
                    <>
                     <li className="nav-item float-right mr-lg-4 pt-4 pt-lg-0">
                        <a
                          href={BLOG_URL}
                          className="navbar-nav-link"
                          // target="_blank" 
                          rel="noreferrer"
                          onClick={this.toggleNavbar}
                        >
                          Blog
                        </a>
                      </li>
                     <li className="nav-item float-right mr-lg-4 pt-4 pt-lg-0">
                        <Link
                          to="/why-us"
                          onClick={this.toggleNavbar}
                          className="navbar-nav-link"
                        >
                          Why Us
                        </Link>
                      </li>
                      <li className="nav-item float-right pt-4 pt-lg-0">
                        <Link
                          to="/login"
                          onClick={this.toggleNavbar}
                          className="navbar-nav-link"
                        >
                          Login
                        </Link>
                      </li>
                      <li className="nav-item float-right ml-4 mr-lg-4 pt-4 pt-lg-0">
                        <Link
                          to="/sign-up"
                          onClick={this.toggleNavbar}
                          className="navbar-nav-link btn-primary"
                        >
                          List your practice
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </nav>
          </div>
        </header>
        <Modal
          show={this.state.showLogoutModal}
          onHide={this.toggleLogoutModal}
          centered
          className="forgot-modal"
          size="sm"
        >
          {/*  */}
          <Modal.Header>
            <h4 className="lead font-weight-bold mb-0">Logout</h4>
            <span
              onClick={this.toggleLogoutModal}
              className="btn modal-close-btn flaticon-cancel cursor-pointer"
            ></span>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to logout?</p>
            <div className="d-flex align-items-center justify-content-center justify-content-md-end">
              <a
                href={" "}
                onClick={(e) => {
                  this.handleLogout();
                }}
                className="btn-primary logout-btn"
              >
                Yes
              </a>
              <a 
                href={" "} 
                className="link-btn font-btn text-blue ml-2"
                onClick={(e) => {
                  e.preventDefault()
                  this.toggleLogoutModal()
                }}
              >
                No
              </a>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    currentUser: state.auth.currentUser,
    isAuthenticated: state.auth.isAuthenticated,
  };
};
export default connect(mapStateToProps)(withRouter(Header));
