import {GLOBAL_TYPES} from './action-types';

import {MiddleWare} from '../../interfaces/auth';

const initialState: MiddleWare[] = [];

const reducer = (state = initialState, action: GLOBAL_TYPES): MiddleWare[] => {
  switch (action.type) {
    case 'LANGUAGE':
      return setLanguage(state, action.payload);
    case 'SEARCH_QUERY':
        return setSearchQuery(state, action.payload);
    default:
      return state;
  }
};

export default reducer;

function setLanguage(state: any, payload: any) {
  return {
    ...state,
    setLanguage: payload,
    loading: false,
  };
}

function setSearchQuery(state: any, payload: any) {
  return {
    ...state,
    setSearchQuery: payload
  };
}