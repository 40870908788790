import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import PublicLayout from './PublicLayout'

class Layout extends React.Component<any, any>{
    static propTypes = {
        children: PropTypes.node.isRequired,
    }
   
  
    render() {
        const { children, ...props } = this.props
        return <PublicLayout {...props}>{children}</PublicLayout>
    }
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
    }
}

export default connect(mapStateToProps)(Layout);