import React, { Component } from 'react';
import { Provider } from 'react-redux';
import Route from './routes/index';
import { store } from './lib/store';
import './App.scss';
// import '../src/assets/css/components/multiselect/index.scss';
class App extends Component<any, any>{
  // https://levelup.gitconnected.com/using-jwt-in-your-react-redux-app-for-authorization-d31be51a50d2
  render() {
    return (
      <>
        <Provider store={store}>
            <Route />
        </Provider>
      </>
    );
  }
}

export default App;
