import React, { Component } from "react";
import { connect } from "react-redux";
import { getUserUsingToken } from "../lib/apis/auth";

let userAuth = function (ComposedComponent) {
  class UserAuth extends Component {
    async componentDidMount() {
      // console.log(this.props);
      if (
        this.props.isAuthenticated !== true &&
        localStorage.getItem("access_token")
      ) {
        await this.GetTokenUser();
      }
    }

    GetTokenUser() {
      this.props
        .getUserUsingToken()
        .then((response) => {
          // console.log("getUserUsingToken", response);
        })
        .catch((error, statusCode, message, e) => {
          // console.log("error", error);
          localStorage.removeItem("access_token");
          localStorage.removeItem("practitionerData");
          this.props.history.push(`${process.env.PUBLIC_URL}`);
        });
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state) => {
    return {
      currentUser: state.auth.currentUser,
      isAuthenticated: state.auth.isAuthenticated,
    };
  };

  return connect(mapStateToProps, { getUserUsingToken })(UserAuth);
};

export default userAuth;
