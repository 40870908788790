import axios from 'axios';
import { store } from '../store/index';
import { API_URL } from '../../lib/config/constant';
import * as authActions from '../store/auth/actions';
import Transformer from './Transformer';

const ApiAddress = API_URL;


axios.defaults.baseURL = ApiAddress;
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.defaults.headers.common['Accept-Language'] = 'en';
if (
  localStorage.getItem('access_token') &&
  localStorage.getItem('access_token') !== null &&
  localStorage.getItem('access_token') !== undefined
) {
  // axios.defaults.headers.common['auth'] = localStorage.getItem(
  //   'access_token',
  // );
  // const token = localStorage.getItem("access_token")

  // axios.defaults.headers.Authorization = token ? `Bearer ${token}` : ""
}

axios.interceptors.response.use(
  (  response: any) => response,
  (  error: { response: { status: any; data: any; }; message: string; }) => {
    if (error.message === 'Network Error') {
      error.response = {
        status: 500,
        data: {
          message: 'Network Error | Network Unavailable',
        },
      };
    }
    if (error.response.status === 401) {

      /**
       * coment due to error
       */
      const data = Transformer.fetch({});
      store.dispatch(authActions.authLogout(data));
      localStorage.removeItem('access_token');
    }
    if (error.response.status === 500) {
      if (
        !error.response.data ||
        !error.response.data.message ||
        error.response.data.message !== ''
      ) {
        error.response = {
          status: 500,
          data: {
            message: error.response.data.message,
          },
        };
      }
    }
    return Promise.reject(error);
  },
);

export default axios;
