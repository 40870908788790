import { SEARCH_QUERIES } from "./action-types";

const initialState = { };

export const searchQueries = (
  state = initialState,
  { type, payload = null }: any
): any => {
  switch (type) {
    case SEARCH_QUERIES:
      return {
        ...state,
        searchQueries: payload,
      };

    default:
      return state;
  }
};
