import { combineReducers } from 'redux';

import auth from './auth/reducer';
import intl from './intl/reducer';
import global from './global/reducer';
import {searchQueries} from './practitioner/reducer';
import { searchResult } from "./home/reducer";

const rootReducers = combineReducers({ auth, intl, global, searchQueries, searchResult });

export type RootState = ReturnType<typeof rootReducers>

export default rootReducers;
